import cn from "classnames";
import { Link } from "gatsby";
import { ReactElement } from "react";

import { CountryCode } from "../../../../settings/countries";
import { homePath } from "../../../../utils/locale-configuration-utils";
import { Image } from "../../../utils/images";
import * as styles from "./Header.module.scss";

const logoSVG: Image = require("../../images/barkibu.svg") as Image;

const logoSVGSrc: string = logoSVG.default;

interface HeaderProps {
  countryCodeIso?: CountryCode;
  adoptionClassName?: string;
}

const Header = ({
  countryCodeIso = CountryCode.ES,
  adoptionClassName,
}: HeaderProps): ReactElement => {
  return (
    <header className={cn(styles.header, adoptionClassName)}>
      <Link to={homePath(countryCodeIso)} className={styles.brandLogo}>
        <img src={logoSVGSrc} alt="Barkibu logo" />
      </Link>
    </header>
  );
};

export default Header;
