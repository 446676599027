import { graphql } from "gatsby";

import ApplePay from "../../features/landings/ui/pages/apple-pay/ApplePay";
import { ApplePayLandingLocal } from "../../features/landings/utils/contentful-types";
import withLandingWrapper from "../../organisms/with_landing_wrapper";

const ApplePayLanding = ({ data }: ApplePayLandingLocal) => <ApplePay data={data} />;

export default withLandingWrapper(ApplePayLanding);

export const query = graphql`
  query ApplePayLandingQuery($id: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    contentfulApplePayLandingLocal(id: { eq: $id }) {
      slug
      locale: node_locale
      metaTitle
      metaDescription
      hero {
        ...HeroFragment
      }
      benefits {
        ...KspFragment
      }
      faqs {
        ...FaqsFragment
      }
      footerTradeMark {
        internal {
          content
        }
      }
    }
    alternateVersions: allContentfulApplePayLandingGlobal {
      nodes {
        localizedContent {
          id
          slug
          locale: node_locale
        }
      }
    }
  }
`;
