import parse from "html-react-parser";
import React, { ReactElement } from "react";

import { Image } from "../../../../../core/utils/images";
import * as styles from "./Footer.module.scss";

const logoSVG = require("../../../../../core/ui/images/barkibu.svg") as Image;
const applePayLogoSVG = require("../../images/apple-pay/apple-pay.svg") as Image;

const logoSVGSrc: string = logoSVG.default;
const applePayLogoSVGSrc: string = applePayLogoSVG.default;

const FooterApplePay = ({ tradeMark }): ReactElement => {
  return (
    <footer className={styles.footerApplePay}>
      <div className={styles.imageWrapper}>
        <img src={logoSVGSrc} alt="Barkibu logo" />
      </div>
      <div className={styles.imageWrapper}>
        <img src={applePayLogoSVGSrc} alt="Apple Pay logo" />
      </div>
      <p className={styles.tradeMark}>{parse(tradeMark)}</p>
    </footer>
  );
};

export default FooterApplePay;
