import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";

import ContactLink from "../../atoms/contact-link/ContactLink";
import { ContactMethodElement } from "../../settings/countries";
import * as styles from "./ContactLinks.module.scss";

interface ContactLinksProps {
  contactMethods: Array<ContactMethodElement>;
  adoptionClassname?: string;
  trackingId: string;
}

const ContactLinks = ({
  contactMethods,
  adoptionClassname,
  trackingId,
}: ContactLinksProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <section className={cn("contact-links", styles.contactLinks, adoptionClassname)}>
      <p className={cn(styles.message)}>
        {t("common.contact.message")} <span>{t("common.contact.schedule")}</span>
      </p>
      <address className={cn(styles.address, { [styles.hasSeparator]: contactMethods.length > 1 })}>
        {contactMethods.map((method) => {
          const { type, destination, phoneNumberToShow, messageToShow } = method;

          return (
            <ContactLink
              key={type}
              destination={destination}
              type={type}
              phoneNumberToShow={phoneNumberToShow}
              trackingId={`${trackingId} - ${type}`}
              messageToShow={messageToShow}
            />
          );
        })}
      </address>
    </section>
  );
};

export default ContactLinks;
