import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";

import TrackableLink from "../trackable-link/TrackableLink";
import * as styles from "./ContactLink.module.scss";

interface ContactLinkProps {
  destination: string;
  type: string;
  phoneNumberToShow?: string;
  trackingId?: string;
  messageToShow?: string;
}

const ContactLink = ({
  destination,
  type,
  phoneNumberToShow,
  trackingId,
  messageToShow,
}: ContactLinkProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TrackableLink
      className={cn("contact-link", styles.contactLink)}
      to={destination}
      aria-label={t(`common.${type}_contact.aria_label`)}
      title={t(`common.${type}_contact.title`)}
      eventSender={trackingId}
      openInNewWindow
    >
      <svg className={cn("icon", `icon--${type}`, styles.icon)} role="img">
        <use href={`#icon-${type}`}></use>
      </svg>
      {phoneNumberToShow && (
        <span className={cn(styles.phoneNumberToShow)}>{phoneNumberToShow}</span>
      )}
      {messageToShow && <span className={cn(styles.messageToShow)}>{t(messageToShow)}</span>}
    </TrackableLink>
  );
};

export default ContactLink;
